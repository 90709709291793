import { render, staticRenderFns } from "./PomodrSumStatistics.vue?vue&type=template&id=139207cc&scoped=true&"
import script from "./PomodrSumStatistics.vue?vue&type=script&lang=js&"
export * from "./PomodrSumStatistics.vue?vue&type=script&lang=js&"
import style0 from "./PomodrSumStatistics.vue?vue&type=style&index=0&id=139207cc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "139207cc",
  null
  
)

export default component.exports