import VueCookies from 'vue-cookies';

export const tableProperties = [
  {
    label: '年份',
    prop: 'year',
    itemType: 'date',
    type: 'year',
    valueFormat: 'yyyy',
    input: true,
    sortable: false
  },
  {
    label: '月份',
    prop: 'month',
    otherProp: 'monthList',
    itemType: 'select',
    options: [
      { value: 1, label: '1月' },
      { value: 2, label: '2月' },
      { value: 3, label: '3月' },
      { value: 4, label: '4月' },
      { value: 5, label: '5月' },
      { value: 6, label: '6月' },
      { value: 7, label: '7月' },
      { value: 8, label: '8月' },
      { value: 9, label: '9月' },
      { value: 10, label: '10月' },
      { value: 11, label: '11月' },
      { value: 12, label: '12月' }
    ],
    input: true,
    sortable: false,
    collapseTags: true,
    filterable: true,
    multiple: true
  },
  {
    label: '业务员',
    otherProp: 'stff_id',
    prop: 'stff_name',
    itemType: 'select',
    options: [],
    filterable: true,
    input: true,
    sortable: false
  },
  {
    label: '类别',
    prop: 'category',
    itemType: 'select',
    options: [
      { label: '进仓', value: '进仓' },
      { label: '组合', value: '组合' }
    ],
    input: true,
    sortable: false,
    visible: VueCookies.get('userInfo').dept_id !== 20
  },
  {
    label: '供应商简称',
    otherProp: 'supp_id',
    prop: 'supp_abbr',
    itemType: 'select',
    options: [],
    filterable: true,
    input: true,
    sortable: false
  },
  {
    label: '供应商等级',
    prop: 'supp_grade',
    itemType: 'input',
    input: false,
    sortable: false
  },
  {
    label: '产品类型',
    prop: '产品类型',
    secondaryHeader: [
      {
        label: '新款',
        prop: 'category_new',
        itemType: 'input',
        input: false,
        sortable: false
      },
      {
        label: '翻单',
        prop: 'category_old',
        itemType: 'input',
        input: false,
        sortable: false
      }
    ]
  },
  {
    label: '货号数量',
    prop: '货号数量',
    secondaryHeader: [
      {
        label: '5000以下新',
        prop: 'small_five_new',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5000以下总',
        prop: 'small_five_sum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '金额',
        prop: 'small_five_price',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5000-2万新',
        prop: 'big_five_new',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5000-2万总',
        prop: 'big_five_sum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '金额',
        prop: 'big_five_price',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '2万-5万新',
        prop: 'big_two_new',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '2万-5万总',
        prop: 'big_two_sum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '金额',
        prop: 'big_two_price',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5万-10万新',
        prop: 'big_fiveten_new',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '5万-10万总',
        prop: 'big_fiveten_sum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '金额',
        prop: 'big_fiveten_price',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '10万以上新',
        prop: 'big_ten_new',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '10万以上总',
        prop: 'big_ten_sum',
        itemType: 'input',
        input: false,
        sortable: true
      },
      {
        label: '金额',
        prop: 'big_ten_price',
        itemType: 'input',
        input: false,
        sortable: true
      }
    ]
  }
];
